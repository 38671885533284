import { css } from "@emotion/core"

export const color = {
  lightpurple: "#89899D",
  darkpurple: "#3A3956",
  red: "#E92B57",
}

// == Units of Measurement == //
const unit = 1
const units = {
  "100": `${unit * 0.5}rem`,
  "200": `${unit}rem`,
  "300": `${unit * 1.5}rem`,
  "400": `${unit * 2}rem`,
  "500": `${unit * 3}rem`,
  "600": `${unit * 4}rem`,
  "700": `${unit * 5}rem`,
  "800": `${unit * 6}rem`,
  "900": `${unit * 7.5}rem`,
}

export const padding = { ...units }
export const margin = { ...units }

// == Typography == //
// Using 'Perfect 4th' scaling (1.333) – Read more at type-scale.com
export const BASE_FONT_SIZE = `${18}px`

export const H1 = css`
  font-size: 4.209em;
  font-weight: 600;
  margin-bottom: 0.4em;
  font-family: "Product Sans", sans-serif;
`

export const H2 = css`
  font-size: 3.157em;
  font-weight: 600;
  margin-bottom: 0.4em;
  font-family: "Product Sans", sans-serif;
`

export const H3 = css`
  font-size: 2.369em;
  font-weight: 600;
  margin-bottom: 0.4em;
  font-family: "Product Sans", sans-serif;
`

export const H4 = css`
  font-size: 1.777em;
  font-weight: 600;
  margin-bottom: 0.4em;
  font-family: "Product Sans", sans-serif;
`

export const H5 = css`
  font-size: 1.333em;
  font-weight: 500;
  margin-bottom: 0.4em;
`

export const H6 = css`
  font-size: 0.75em;
  margin-bottom: 0.4em;
`

export const P = css`
  font-size: 1em;
  line-height: 1.4;
  margin-bottom: 0.4em;
`

// == Broad Defaults == //
export const WRAPPER_WIDTH = `${1146}px`
